import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { FaFacebookSquare } from "react-icons/fa"
import { FaTwitter } from "react-icons/fa"
import { FaEnvelope } from "react-icons/fa"
import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
} from "react-share"

export default ({ ...props }) => (
  <ShareWrapper css={css``}>
    <div
      css={css`
        font-weight: 500;
        padding-top: 4px;
        font-size: 15px;
        color: rgba(44, 44, 44, 0.97);
      `}
    >
      Partager cette page&nbsp;:
    </div>
    <FacebookShareButton
      url={`https://www.docteurphilipperoure.com/${props.slug}`}
    >
      <FaFacebookSquare
        css={css`
          font-size: 22px;
          margin: 0 15px 0 10px;
          padding-top: 7px;
          cursor: pointer;
          color: rgba(44, 44, 44, 0.97);
          :hover {
            color: #e6ae48;
          }
        `}
      />
    </FacebookShareButton>
    <TwitterShareButton url={`${props.slug}`} title={props.metaTitle}>
      <FaTwitter
        css={css`
          font-size: 22px;
          margin-right: 16px;
          padding-top: 7px;
          cursor: pointer;
          color: rgba(44, 44, 44, 0.97);
          :hover {
            color: #e6ae48;
          }
        `}
      />
    </TwitterShareButton>
    <EmailShareButton
      url={`${props.slug}`}
      subject={props.title}
      body={`${props.slug}`}
    >
      <FaEnvelope
        css={css`
          font-size: 22px;
          padding-top: 7px;
          cursor: pointer;
          color: rgba(44, 44, 44, 0.97);
          :hover {
            color: #e6ae48;
          }
        `}
      />
    </EmailShareButton>
  </ShareWrapper>
)

const ShareWrapper = styled.div`
  margin: 0.5em 0 0;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 678px) {
    margin-right: 5px;
  }
`
